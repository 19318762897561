<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <search @success="getList">
          <el-form style="margin:11px 0px 0px -26px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period"></qzf-period>
            </el-form-item>
            <el-form-item label="选择类别 ：" :label-width="formLabelWidth">          
                <el-select v-model="listQuery.optionType" placeholder="请选择" size="small" style="width:200px">
                  <el-option label="普通科目明细账" value="1">
                  </el-option>
                  <el-option label="外币科目明细账" value="3">
                  </el-option>
                  <el-option label="数量金额科目明细账" value="2">
                  </el-option>
                </el-select>
              </el-form-item>
            <el-form-item label="会计期间：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fseNot0" label="发生额为0不显示"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>

      </div>
      <div  class="right_box">
        <el-dropdown>
          <qzf-button jz="false" button_code="zb_mxz_dydc" type="primary" size="small">
            打印导出<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_mxz_dyqb" @click="printAll()" type="success" size="small">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_mxz_dcqb" @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-row>
      <el-col :span="this.leftStatus?0:5">
        <div class="searchGjz">
          <el-input v-model="filterText" placeholder="请输入关键字" style="width:100%;margin-bottom: 10px;" size="small"></el-input>
          <el-tree ref="tree" class="elTree" highlight-current :filter-node-method="filterNode" :data="data" :props="defaultProps" @node-click="handleNodeClick" node-key="id" style="width:100%;height:500px;overflow:scroll;box-shadow: rgb(219 211 211 / 58%) 2px 2px 7px 3px;" v-loading="loading"></el-tree>
        </div>
      </el-col>
      <el-col :span="this.leftStatus?24:19">
        <el-icon class="leftButtonIcon" @click="stowLeft" v-if="!this.leftStatus"><DArrowLeft /></el-icon>
        <el-icon class="leftButtonIcon" @click="stowLeft" v-else><DArrowRight /></el-icon>
        <!-- <el-button size="small" @click="stowLeft">收起</el-button> -->
        <span v-if="nowSubjectCode" style="color:#3c6ff2;font-size: 12px;margin-left: 3px;">{{nowSubjectCode}}
         <el-button size="small" type="primary" @click="handleNodeClick(this.listOne)" style="margin-bottom:4px">刷新</el-button>
        </span>
        <div v-if="listQuery.optionType == '1'">
          <el-table stripe :height="contentStyleObj" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>
          <el-table-column label="借方" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.inAmount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
              <template #default="scope">
                <span>{{scope.row.outAmount}}</span>
              </template>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.endAmount}}</span>
            </template>
          </el-table-column>
        </el-table>
        </div>
        <div v-if="listQuery.optionType == '2'">
          <el-table :height="contentStyleObj" size="small" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
          <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{scope.row.countIn}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutDan" label="单价" width="120">
              <template #default="scope">
                <span>{{scope.row.priceIn}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutJin" label="金额" width="120">
              <template #default="scope">
                <span>{{scope.row.inAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{scope.row.countOut}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutDD" label="单价" width="120">
              <template #default="scope">
                <span>{{scope.row.priceOut}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutDJ" label="金额" width="120">
              <template #default="scope">
                <span>{{scope.row.outAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginIn" label="数量" width="120">
              <template #default="scope">
                <span>{{(scope.row.endCount)}}</span>
              </template>
            </el-table-column>
            
            <el-table-column align="center" prop="periodBeginOutYD" label="单价" width="120">
              <template #default="scope">
                <span>{{scope.row.endPrice}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutYJ" label="金额" width="120">
              <template #default="scope">
                <span>{{scope.row.endAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        </div>
        <div v-if="listQuery.optionType == '3'">
          <el-table :height="contentStyleObj" size="small" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 98%;margin-left: 25px;">
          <el-table-column label="日期" align="center" min-width="100">
            <template #default="scope">
              <!-- <span v-if="scope.row.voucherDate">{{ scope.row.voucherDate }}</span> -->
              <span>{{ scope.row.voucherDate }}</span>
            </template>
          </el-table-column>

          <el-table-column label="账期" align="center" min-width="100">
            <template #default="scope">
              <span>{{scope.row.period}}</span>
            </template>
          </el-table-column>

          <el-table-column label="凭证字号" align="center" min-width="100">
            <template #default="scope">
              <span @click="gotoSubjectItem(scope.row)" style="color:#F15A24;cursor: pointer;">{{scope.row.voucherNo}}</span>
            </template>
          </el-table-column>

          <el-table-column label="摘要" align="center" min-width="180">
            <template #default="scope">
              <span style="font-weight: bold;" v-if="scope.row.summary == '期初余额' || scope.row.summary == '本期合计' || scope.row.summary == '本年累计'">{{scope.row.summary}}</span>
              <span v-else>{{scope.row.summary}}</span>
            </template>
          </el-table-column>

          <el-table-column label="借方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutW" label="外币" width="120">
              <template #default="scope">
                <span>{{scope.row.wbInAmount}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutBen" label="本位币" width="120">
              <template #default="scope">
                <span>{{scope.row.inAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="贷方" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutDW" label="外币" width="120">
              <template #default="scope">
                <span>{{scope.row.wbOutAmount}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutDB" label="本位币" width="120">
              <template #default="scope">
                <span>{{scope.row.outAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="方向" align="center" min-width="150">
            <template #default="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="余额" align="center" min-width="150">
            <el-table-column align="center" prop="periodBeginOutYY" label="外币" width="120">
              <template #default="scope">
                <span>{{scope.row.wbEndAmount}}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" prop="periodBeginOutYB" label="本位币" width="120">
              <template #default="scope">
                <span>{{scope.row.endAmount}}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- <printVouch></printVouch> -->
    <printVouch ref="printVouch" :name="this.name1" :listQuery1="this.listQuery" :DY="this.DY"></printVouch>
    <exportFile ref="exportFile" name="book_detail_account" :listQuery1="this.listQuery"></exportFile>
  </div>
</template>

<script>
import { subjectTreeList } from "@/api/subject.js"
import { bookDetailAccountList } from "@/api/book.js"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
export default {
  name:'detailAccount',
  components: { printVouch,exportFile },
  data() {
    return {
      name1:'',
      DY: 0,
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      filterText: '',
      // optionType: '1',
      listLoading: false,
      loading:false,

      data:[],
      defaultProps: {
        children: 'children',
        label: 'name3',
      },
      list: [],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime: '',
        endTime: '',
        subjectId: 0,
        // fzhsId: 0,
        level: 0,
        not0: false,
        fseNot0:false,
        optionType: '1',
      },
      leftStatus:false,
      nowSubjectCode:'',
      listOne:undefined
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(300)
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    getList(e) {
      this.loading = true
      subjectTreeList(this.listQuery).then(res=>{
        this.data = res.data.data.list
        this.loading = false
      })
      if(e){
        e.id = e.subjectId
        this.handleNodeClick(e)
      }
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    printAll(e) {
      this.DY = e
      this.name1 = 'book_detail_account',
      this.$nextTick(()=>{
      this.$refs.printVouch.init()
      })
      
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name3.indexOf(value) !== -1
    },
    handleNodeClick(item) {
      this.listOne = item
      this.listQuery.subjectId = Number(item.id)
      // this.listQuery.fzhsId = Number(item.fzhsItemId)
      this.listLoading = true
      bookDetailAccountList(this.listQuery).then(res => {
        if(res.data.data.info) {
          this.list = res.data.data.info
          this.listLoading = false
          this.nowSubjectCode = this.list[0].subjectCode
        }else {
          this.list = []
          this.listLoading = false
        }
      })
    },
    gotoSubjectItem(row){
      let voucherId = row.voucherId
      if(row.period < this.$store.getters['user/comInfo'].startPeriod ){
        this.$store.dispatch('commons/setParam', {historyVoucherId: voucherId,hisVoucherPeriod: this.listQuery.period})
        this.$store.dispatch('tagsView/delCachedViewByName', "historyVoucher")
        this.$router.push({
          path: "/bookkeeps/components/historyVoucher",
          name: "historyVoucher",
        });
      }else{
        this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
      }
    },
    stowLeft(){
      if(this.leftStatus){
        this.leftStatus = false
        console.log("xx");
      }else{
        this.leftStatus = true
        console.log("33");

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.searchBtn {
  text-align: center;
  button {
    margin-right: 5px;
  }
}
.top_btns {
  margin-bottom: 20px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.elTree {
  width: 100%;
  height: 500px;
  overflow: scroll;
  // box-shadow: rgb(219 211 211 / 58%) 5px 8px 8px 3px;
  border: 1px solid #ede5e5;
}
::-webkit-scrollbar {
    width: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(17, 16, 16, 0.13);
    -webkit-box-shadow: rgba(0, 0, 0, 0.9);
    box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #efefef;
}
.leftButtonIcon{
  position: relative;
  top: 255px;
  font-size:20px;
  color:#3c6ff2;
  cursor: pointer;
}
</style>
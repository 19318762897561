import request from '@/utils/request'

// 凭证汇总
export function bookAllVoucherList(data) {
  return request({
      url: 'api/v2/book/bookAllVoucherList',
      method: 'post',
      data
  })
}
// 总账
export function bookAllAccountList(data) {
  return request({
      url: 'api/v2/book/bookAllAccountList',
      method: 'post',
      data
  })
}
// 明细账
export function bookDetailAccountList(data) {
  return request({
      url: 'api/v2/book/bookDetailAccountList',
      method: 'post',
      data
  })
}

// 核算项目明细账
export function detailFzhsList(data) {
  return request({
      url: 'api/v2/book/detailFzhsList',
      method: 'post',
      data
  })
}
// 多栏账
export function multiColumnList(data) {
  return request({
      url: 'api/v2/book/multiColumnList',
      method: 'post',
      data
  })
}

// 核算项目明细账内容
export function detailFzhs(data) {
    return request({
        url: 'api/v2/book/detailFzhs',
        method: 'post',
        data
    })
  }
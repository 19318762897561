<template>
  <div class="app-container">
    <div class="top_btns clearfix">
      <div class="left_box">
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 0px;width:430px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period"></qzf-period>
            </el-form-item>
            <el-form-item label="会计期间：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
            <el-form-item label="选择级别 ：" :label-width="formLabelWidth">
              <el-input placeholder="1" style="width: 90px;" class="filter-item" size="small" :disabled="true" />
              <span>-</span>
              <el-input placeholder="请输入级别" v-model.number="listQuery.level" style="width: 90px;margin-right:10px" class="filter-item" size="small" />
            </el-form-item>
            <el-form-item label="科目编码 ：" :label-width="formLabelWidth">
              <subject-list-all v-model:subjectId="listQuery.subjectId"></subject-list-all>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="余额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.fseNot0" label="发生额为0不显示"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <el-dropdown>
          <qzf-button jz="false" button_code="zb_zz_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dya4h" @click="printing()" type="success" size="small">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dc" @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-table stripe :height="contentStyleObj"  :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey" :data="seachlist" border fit highlight-current-row @sort-change="sortChange" style="width: 100%">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" align="center">
        <template #default="scope">
          <span class="bianma" @click="gotoDetail(scope.row.subjectCode)">{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="科目名称" align="center">
        <template #default="scope">
          <span>{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="期间" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{item.period}}</p>
            <p>{{item.period}}</p>
            <p>{{item.period}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="摘要" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>期初余额</p>
            <p>本期合计</p>
            <p>本年累计</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="借方" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{item.periodBeginIn}}</p>
            <p>{{item.periodInt}}</p>
            <p>{{item.yearInt}}</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="贷方" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{item.periodBeginOut}}</p>
            <p>{{item.periodOut}}</p>
            <p>{{item.yearOut}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="余额" align="center">
        <template #default="scope">
          <div class="div_p" v-for="(item,index) in scope.row.item" :key="index">
            <p>{{item.endAmount1}}</p>
            <p>{{item.endAmount2}}</p>
            <p>{{item.endAmount3}}</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <printVouch ref="printVouch" name="book_all_account" :listQuery1="this.listQuery"></printVouch>
    <exportFile ref="exportFile" name="book_all_account" :listQuery1="this.listQuery"></exportFile>
  </div>
</template>

<script>
import { bookAllAccountList } from "@/api/book.js"
// import { accountBook,exportBook } from "@/api/printSet"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
import SubjectListAll from '../../../components/subject/subjectListAll.vue'
export default {
  name:'generalLedger',
  components: {
    printVouch,
    exportFile,
    SubjectListAll
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      formLabelWidth: '120px',
      seachlist: [],
      list: null,
      listLoading: false,
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        not0: false,
        fseNot0: false,
        name: '',
        level: 1,
        beginTime:'',
        endTime:''
      },
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(300)
  },
  watch: {

  },
  methods: {
    printing() {
      this.$refs.printVouch.init()
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    getList() {
      // this.listLoading = true;
      bookAllAccountList(this.listQuery).then(res => {
        this.listLoading = false;
        if(!res.data.data.info){
          res.data.data.info = []
        }
        let list = res.data.data.info;
        list.map(item=>{
          item.item.map(v=>{
            v.endAmount1 = v.beginType + " " + v.endAmount1
            v.endAmount2 = v.periodType + " " + v.endAmount2
            v.endAmount3 = v.endType + " " + v.endAmount3
          })
        })
        this.seachlist = list;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.searchGjz {
  border: 1px solid #ebeef5;
}
.searchBtn {
  text-align: center;
  button {
    margin-right: 5px;
    padding: 10px 0 10px 0;
  }
}
.top_btns {
  margin-bottom: 20px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
</style>
<template>
  <div class="app-container">
  <!-- top -->
    <div class="top_btns clearfix">
      <div class="left_box">
        <!-- 筛选 -->
        <search @success="getList">
          <el-form style="margin:11px 0px 0px -26px;height:270px;width:430px" class="styleForm">
            <el-form-item label="选择类别 ：" :label-width="formLabelWidth">          
              <el-select v-model="wbstatus" placeholder="请选择" size="small" style="width:200px">
                <el-option label="普通科目余额表" value="">
                </el-option>
                <el-option label="外币科目余额表" value="wb">
                </el-option>
                <el-option label="数量金额科目余额表" value="sl">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period" v-model:initPeriod="listQuery.initPeriod" @success="getList" :lastStartPeriod="lastStartPeriod"></qzf-period>
            </el-form-item>
            <el-form-item label="会计期间：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
            <el-form-item label="选择级别 ：" :label-width="formLabelWidth">
              <el-input placeholder="1" style="width: 90px;" class="filter-item" size="small" :disabled="true" />
              <span>-</span>
              <el-input placeholder="请输入级别" v-model.number="listQuery.level" style="width: 90px;margin-right:10px" class="filter-item" size="small" />
            </el-form-item>
            <el-form-item label="科目编码 ：" :label-width="formLabelWidth">
              <el-input placeholder="请输入" v-model="listQuery.name" style="width: 180px;" class="filter-item" size="small" />
              <!-- <subject-list-all v-model:subjectId="listQuery.subjectId"></subject-list-all> -->
            </el-form-item>
            <el-form-item label-width="42px" style="height:10px"> 
              <el-checkbox v-model="listQuery.not0" label="金额为0不显示"></el-checkbox>
            </el-form-item>
            <el-form-item label-width="42px">
              <el-checkbox v-model="yearAmount" label="隐藏本年累计发生额"></el-checkbox>
            </el-form-item>
          </el-form>
        </search>
      
      </div>
      <div class="right_box">
        <el-dropdown>
          <qzf-button jz="false" button_code="zb_zz_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dya4h" @click="printingA5()" type="success" size="small">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_zz_dc" @click="daochu()" type="primary" size="small">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
              <el-dropdown-item v-if="this.tongbu">
                <qzf-button jz="false" button_code="zb_zz_dc" @click="tongbu()" type="primary" size="small">同步科目</qzf-button>
              </el-dropdown-item>
              <el-dropdown-item v-if="this.tongbu">
                <qzf-button jz="false" button_code="zb_zz_dc" @click="tongbu2()" type="primary" size="small">同步余额</qzf-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button type="text" @click="sumsAlert">点击查看统计</qzf-button>
      </div>
    </div>
    <!-- top end -->

    <el-table show-summary :summary-method="getSummaries" stripe :height="contentStyleObj" :data="showList" border  v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" width="160" align="center">
        <template #default="scope">
          <span class="bianma" @click="tiaozhuan(scope.row)" style="cursor: pointer;color: #3c6ff2;">{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>

      <el-table-column label="科目名称" min-width="150" align="center">
        <template #default="scope">
          <span class="name_css">{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>

      <el-table-column label="期初余额" align="center">
        <el-table-column  align="center" prop="periodBeginIn" label="借方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.periodBeginIn}}</span>
            <input v-if="unequal"
            v-model.number="scope.row.periodBeginIn"
            class="xginput"
            placeholder="请输入金额">
          </template>
        </el-table-column>
        <el-table-column  align="center" prop="periodBeginOut" label="贷方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.periodBeginOut}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.periodBeginOut"
            class="xginput"
            style="border: 1px solid #b3aeae"
            placeholder="请输入金额">
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodBeginInWb" label="外币借方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.periodBeginInWb}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.periodBeginInWb"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodBeginOutWb" label="外币贷方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.periodBeginOutWb}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.periodBeginOutWb"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'sl'" align="center" prop="beginCount" label="数量" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.beginCount}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.beginCount"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="本期发生额" align="center">
        <el-table-column align="center" prop="periodInt" label="借方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodInt}}</span>
          </template>
        </el-table-column>  
        <el-table-column align="center" prop="periodOut" label="贷方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodOut}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodIntWb" label="外币借方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodIntWb}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodOutWb" label="外币贷方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodOutWb}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'sl'" align="center" prop="inCount" label="数量借" width="120">
          <template #default="scope">
            <span>{{scope.row.inCount}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'sl'" align="center" prop="outCount" label="数量贷" width="120">
          <template #default="scope">
            <span>{{scope.row.outCount}}</span>
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="本年累计发生额" align="center" v-if="yearAmount == false">
        <el-table-column align="center" prop="yearInt" label="借方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.yearInt}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.yearInt"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>
        <el-table-column align="center" prop="yearOut" label="贷方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.yearOut}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.yearOut"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="yearIntWb" label="外币借方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.yearIntWb}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.yearIntWb"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="yearOutWb" label="外币贷方" width="160">
          <template #default="scope">
            <span v-if="equal">{{scope.row.yearOutWb}}</span>
            <input type="float" v-if="unequal"
            v-model.number="scope.row.yearOutWb"
            style="border: 1px solid #b3aeae;width: 120px;height: 28px;line-height: 28px;color: #606266;outline: none;"
            placeholder="请输入金额">
          </template>
        </el-table-column>

      </el-table-column>

      <el-table-column label="期末余额" align="center">
        <el-table-column align="center" prop="periodEndIn" label="借方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodEndIn}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="periodEndOut" label="贷方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodEndOut}}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodEndInWb" label="外币借方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodEndInWb}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="wbstatus == 'wb'" align="center" prop="periodEndOutWb" label="外币贷方" width="120">
          <template #default="scope">
            <span>{{scope.row.periodEndOutWb}}</span>
          </template>
        </el-table-column>


        <el-table-column v-if="wbstatus == 'sl'" align="center" prop="endCount" label="数量" width="120">
          <template #default="scope">
            <span>{{scope.row.endCount}}</span>
          </template>
        </el-table-column>

      </el-table-column>
    </el-table>
    <div style="text-align: center;margin-top: 15px;" v-if="unequal">
      <qzf-button @success="saveZcfz" type="primary" size="small">
        <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
        <span  >保存</span>
      </qzf-button>
    </div>
    <printVouch ref="printVouch" name="book_subject_balance" :listQuery1="this.listQuery"></printVouch>
    <exportFile ref="exportFile" name="book_subject_balance" :listQuery1="this.listQuery"></exportFile>

    <el-dialog
      title="查看统计"
      v-model="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <div v-html="this.str"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            关闭
          </el-button>
        </span>
    </template>
    </el-dialog>
  </div>
</template>

<script>
import { oldTongbuSubject,oldNewSubjectSync } from "@/api/old"

import { subjectBalanceList,subjectBalanceSave } from "@/api/subject"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
import { accountBook } from "@/api/printSet"
import SubjectListAll from '../../../components/subject/subjectListAll.vue'
export default {
  name: 'subjectBalance',
  components: {
    printVouch,
    exportFile,
    SubjectListAll
  },
  props:{
    tongbu:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      name1:'',
      showList:[],
      formLabelWidth: '120px',
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period: this.$store.getters['user/comInfo'].period,
        initPeriod: "",
        not0: false,
        name: '',
        level: 1,
        beginTime:'',
        endTime:'',
      },
      contentStyleObj:{},
      wbstatus: '',
      visible:false,
      yearAmount:false,
      checkbox:false,
      loading:false,
      lastStartPeriod:this.$store.getters['user/comInfo'].lastStartPeriod,
      str:'',
      dialogVisible:false
    }
  },
  created() {
    this.initBus()
    this.contentStyleObj= this.$getHeight(300)
    this.getList()
  },
  computed: {
    equal() {
      return this.listQuery.period != this.$store.getters['user/comInfo'].startPeriod 
    },
    unequal() {
      return this.listQuery.period == this.$store.getters['user/comInfo'].startPeriod && ((this.listQuery.beginTime == '' && this.listQuery.endTime == '')  || (this.listQuery.beginTime == this.$store.getters['user/comInfo'].startPeriod && this.listQuery.endTime == this.$store.getters['user/comInfo'].startPeriod))
    }
  },
  methods:{
    initBus(){
      this.$bus.off("subjectBalanceUpdate")
      this.$bus.on("subjectBalanceUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.loading = true
      subjectBalanceList(this.listQuery).then(res=>{
        this.loading = false
        if(res.data.data.list){
          this.showList = res.data.data.list
        }else{
          this.showList = []
        }
        this.assets = res.data.data.assest
        this.liabilities = res.data.data.liabilities
        this.interests = res.data.data.interests
        this.cost = res.data.data.cost
        this.profitAndLoss = res.data.data.profitAndLoss
      })
    },
    printingA5() {
      // this.$refs.printVouch.init('','','',this.wbstatus)
      this.$refs.printVouch.init()
    },
    daochu() {
      this.$refs.exportFile.init(this.wbstatus)
    },
    printing() {
      let params = {
        query: this.listQuery,
        bookNames: ['book_subject_balance'],
        comIds:[this.listQuery.comId],
        paperType: "A4-H"
      }
      if(this.listQuery.endTime<this.listQuery.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
      }else{
        accountBook(params).then(res=>{
          if (res.data.msg != "success") {
            this.$message.error(res.data.msg);
          }else{
            window.open(res.data.data.url);  
          }
        })
      }
    },
    saveZcfz() {
      this.showList.map(v=>{
        v.periodBeginIn = Number(v.periodBeginIn)
        v.periodBeginOut = Number(v.periodBeginOut)
        v.periodInt = Number(v.periodInt)
        v.periodOut = Number(v.periodOut)
        v.yearInt = Number(v.yearInt)
        v.yearOut = Number(v.yearOut)
        v.periodEndIn = Number(v.periodEndIn)
        v.periodEndOut = Number(v.periodEndOut)
        v.periodBeginInWb = Number(v.periodBeginInWb)
        v.periodBeginOutWb = Number(v.periodBeginOutWb)
        v.periodIntWb = Number(v.periodIntWb)
        v.periodOutWb = Number(v.periodOutWb)
        v.yearIntWb = Number(v.yearIntWb)
        v.yearOutWb = Number(v.yearOutWb)
      })
      let str = "" 
      let param = this.showList;
      param.map(v=>{
        let codeStr = this.findDirectSubordinateAmount(v, param);
        if (codeStr != "") {
          str = codeStr
        }
      })
      if(str != ""){
        this.fullscreenLoading = false
        this.$message.error(str + "下级余额不等于此科目");
        return
      }
      subjectBalanceSave(this.showList).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功", 3)
          this.getList()
        }
      })
    },
    // 科目限制
    findDirectSubordinateAmount(codeBalance,subjects){
      let code = codeBalance.subjectCode
      let codeLen = this.SubjectLens(code)
      let status = false // 是否存在下级
      let amountBeginIn = 0.0
      let amountBeginOut = 0.0
      let amountYearIn = 0.0
      let amountYearOut = 0.0
      subjects.map(subject=>{
        if(subject.subjectCode.length > code.length && (codeLen+1) == this.SubjectLens(subject.subjectCode) && code + "." == subject.subjectCode.slice(0,code.length + 1)){
          status = true
          amountBeginIn = (amountBeginIn*1 + subject.periodBeginIn*1).toFixed(2)
          amountBeginOut = (amountBeginOut*1 + subject.periodBeginOut*1).toFixed(2)
          amountYearIn = (amountYearIn*1 + subject.yearInt*1).toFixed(2)
          amountYearOut = (amountYearOut*1 + subject.yearOut*1).toFixed(2)
        }
      })
      if (status) {
        if (codeBalance.periodBeginIn != 0) {
          amountBeginIn = (amountBeginIn*1 - amountBeginOut*1).toFixed(2)
          amountBeginOut = 0
        } else {
          amountBeginOut = (amountBeginOut*1 - amountBeginIn*1).toFixed(2)
          amountBeginIn = 0
        }
        if (codeBalance.periodBeginIn != amountBeginIn || codeBalance.periodBeginOut != amountBeginOut || codeBalance.yearInt != amountYearIn || codeBalance.yearOut != amountYearOut) {

          return codeBalance.subjectCode
        }
      }
      return ""
    },
    SubjectLens(code){
      return code.split('.').length
    },
    // 统计
    sumsAlert() {
      let str = `<table style="border:1px solid #c2c2c2;" class="content_table" cellspacing="0" width="100%">
            <tr style="background:#f6f6f6;color:#333;font-weight:600;line-height:32px;">
              <td rowspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:12%">类型</td>
              <td colspan="2"  style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">期初余额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本期发生额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:22%">本年累计发生额</td>
              <td colspan="2" style="border-bottom:1px solid #c2c2c2;text-align: center;width:22%">期末余额</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">借方</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">贷方</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">资产</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.assets.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">负债</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.liabilities.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">权益</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.interests.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">成本</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.cost.periodEndOut}</td>
            </tr>
            <tr style="line-height:28px">
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;">损益</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodBeginOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearInt}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.yearOut}</td>
              <td style="border-bottom:1px solid #c2c2c2;border-right:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndIn}</td>
              <td style="border-bottom:1px solid #c2c2c2;text-align: center;width:11%">${this.profitAndLoss.periodEndOut}</td>
            </tr>
            
          </table>`
          this.str = str
          this.dialogVisible = true
      // this.$alert(str, '数额统计', {
      //   dangerouslyUseHTMLString: true,
      //   customClass:'message_box_alert'
      // });
    },
    handleFilter() {
      this.visible = false
      this.getList()
    },
    getSummaries(param){
      let { columns, data } = param;
      let periodBeginIn = 0
      let periodBeginOut = 0
      let periodInt = 0
      let periodOut = 0
      let yearInt = 0
      let yearOut = 0

      let periodBeginInWb = 0
      let periodBeginOutWb = 0
      let periodIntWb = 0
      let periodOutWb = 0
      let yearIntWb = 0
      let yearOutWb = 0

      let periodEndIn = 0
      let periodEndOut = 0
      let periodEndInWb = 0
      let periodEndOutWb = 0
      data = this.showList
      data.map(v=>{
        if(v.subjectCode.length == 4){
          periodBeginIn +=  Number(v.periodBeginIn)
          periodBeginOut += Number(v.periodBeginOut)
          periodInt += Number(v.periodInt)
          periodOut += Number(v.periodOut)
          periodEndIn += Number(v.periodEndIn)
          periodEndOut += Number(v.periodEndOut)
          yearInt += Number(v.yearInt)
          yearOut += Number(v.yearOut)

          periodBeginInWb += Number(v.periodBeginInWb)
          periodBeginOutWb += Number(v.periodBeginOutWb)
          periodIntWb += Number(v.periodIntWb)
          periodOutWb += Number(v.periodOutWb)
          yearIntWb += Number(v.yearIntWb)
          yearOutWb += Number(v.yearOutWb)
          periodEndInWb += Number(v.periodEndInWb)
          periodEndOutWb += Number(v.periodEndOutWb)
          if(v.type == 1){
            periodEndIn = periodEndIn + v.periodBeginIn - v.periodBeginOut + v.periodInt - v.periodOut
            periodEndInWb = periodEndInWb + v.periodBeginInWb - v.periodBeginOutWb + v.periodIntWb - v.periodOutWb
          }else{
            periodEndOut = periodEndOut - v.periodBeginIn + v.periodBeginOut - v.periodInt + v.periodOut
            periodEndOutWb = periodEndOutWb - v.periodBeginInWb + v.periodBeginOutWb - v.periodIntWb + v.periodOutWb
          }
        }
      })
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "periodBeginIn"){
          sums[index] = periodBeginIn.toFixed(2)
        }else if(column.property == "periodBeginOut"){
          sums[index] = periodBeginOut.toFixed(2)
        }else if(column.property == "periodInt"){
          sums[index] = periodInt.toFixed(2)
        }else if(column.property == "periodOut"){
          sums[index] = periodOut.toFixed(2)
        }else if(column.property == "yearInt"){
          sums[index] = yearInt.toFixed(2)
        }else if(column.property == "yearOut"){
          sums[index] = yearOut.toFixed(2)
        }else if(column.property == "periodBeginInWb"){
          sums[index] = periodBeginInWb.toFixed(2)
        }else if(column.property == "periodBeginOutWb"){
          sums[index] = periodBeginOutWb.toFixed(2)
        }else if(column.property == "periodIntWb"){
          sums[index] = periodIntWb.toFixed(2)
        }else if(column.property == "periodOutWb"){
          sums[index] = periodOutWb.toFixed(2)
        }else if(column.property == "yearIntWb"){
          sums[index] = yearIntWb.toFixed(2)
        }else if(column.property == "yearOutWb"){
          sums[index] = yearOutWb.toFixed(2)
        }else if(column.property == "periodEndIn"){
          sums[index] = periodEndIn.toFixed(2)
        }else if(column.property == "periodEndOut"){
          sums[index] = periodEndOut.toFixed(2)
        }else if(column.property == "periodEndInWb"){
          sums[index] = periodEndInWb.toFixed(2)
        }else if(column.property == "periodEndOutWb"){
          sums[index] = periodEndOutWb.toFixed(2)
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },

    // 历史
    tongbu(){
      this.$confirm('确认同步科目', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        oldTongbuSubject({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功")
          }
        })
      })
    },
    tongbu2(){
      this.$confirm('确认同步科目余额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        oldNewSubjectSync({}).then(res=>{
          this.loading = false
          if(res.data.msg == "success"){
            this.$qzfMessage("同步成功")
          }
        })
      })
    },
    tiaozhuan(row){
      this.$emit('success',row)
    }
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  .left_box{
    float: left;
    width: 70%;
    margin-bottom: 10px;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.styleForm{
  .el-select{
    margin: 0px;
  }
}
.bottom_btns{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  .left_box{
    float: left;
    width: 70%;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
    span{
      color: #F15A24;
    }
  }
}
</style>
<style>

.message_box_alert{
  width: 800px !important;
}
</style>
<style lang="scss">
// .el-message-box{
//   --el-messagebox-width:none;
// }
.styleForm{
  .el-input{
    width: 136px;
  }
}
.el-table__header,.el-table__body,.el-table__footer{
   width: 100%;
   table-layout: fixed !important;
}
.xginput {
  border: 1px solid #b3aeae;
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: #606266;
  outline: none;
  padding: 0 10px;
}
// ::-webkit-scrollbar {
//     width: 1px;
// }
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: rgba(0, 0, 0, 0.3);
//     box-shadow: rgba(0, 0, 0, 0.3);
// }
// /* 滚动条滑块 */
// ::-webkit-scrollbar-thumb {
//     background: rgba(17, 16, 16, 0.13);
//     -webkit-box-shadow: rgba(0, 0, 0, 0.9);
//     box-shadow: rgba(0, 0, 0, 0.5);
// }
// ::-webkit-scrollbar-thumb:window-inactive {
//     background: #efefef;
// }
</style>
<template>
  <div class="app-container">
    <div class="top_btns" style="margin-bottom:0px">
      <div class="">
        <search @success="getList">
          <el-form style="margin:20px 0px 40px 10px;width:400px" class="styleForm">
            <el-form-item label="选择期间 ：" :label-width="formLabelWidth" class="dateFormPeriod" prop="period">
              <qzf-period v-model:period="listQuery.period"></qzf-period>
            </el-form-item>
            <el-form-item label="会计期间：" :label-width="formLabelWidth">
              <qzf-period v-model:period="listQuery.beginTime"></qzf-period>
              <span>-</span>
              <qzf-period v-model:period="listQuery.endTime"></qzf-period>
            </el-form-item>
            <el-form-item label="选择级别：" :label-width="formLabelWidth" style="">
              <el-input placeholder="1" style="width: 80px;" class="filter-item" size="small" :disabled="true" />
              -
              <el-input placeholder="请输入级别" v-model.number="listQuery.level" style="width:80px;" class="filter-item" size="small" />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <p class="top_p">
        <span>凭证总张数：{{counts.count}}张&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;附件总张数：{{counts.billCount}}张</span>
      </p>
      <div class="">
        <el-dropdown style="margin-left: 6px;">
          <qzf-button jz="false" button_code="zb_pzhz_gd" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_pzhz_dya4h" size="small" @click="printing()" type="success">
                <el-icon><Printer /></el-icon> <span  >打印</span>
              </qzf-button></el-dropdown-item>
              <el-dropdown-item><qzf-button jz="false" button_code="zb_pzhz_dc" size="small" @click="daochu()" type="primary">
                <el-icon><FolderOpened /></el-icon> <span  >导出</span>
              </qzf-button></el-dropdown-item>
            </el-dropdown-menu>
          </template>
          
        </el-dropdown>
      </div>
    </div>
    
    <div class="biaotou">
      <span>公司名：{{comName}}</span>
      <span>账期：{{listQuery.period}}</span>
      <span>单位：元</span>
    </div>
    <el-table stripe :height="contentStyleObj" :data="seachlist" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" v-loading="listLoading" :key="tableKey" border fit highlight-current-row @sort-change="sortChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="科目编码" align="center" width="240">
        <template #default="scope">
          <span>{{scope.row.subjectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="科目名称" align="center" width="240">
        <template #default="scope">
          <span>{{scope.row.subjectName}}</span>
        </template>
      </el-table-column>

      
      <el-table-column label="本期合计" align="center">
        <el-table-column prop="periodInt" label="借" width="320" align="center">
          <template #default="scope">
            <span>{{scope.row.periodInt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="periodOut" label="贷" width="320" align="center">
          <template #default="scope">
            <span>{{scope.row.periodOut}}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="方向" align="center">
        <template #default="scope">
          <span v-if="scope.row.type == 1">借</span>
          <span v-if="scope.row.type == 2">贷</span>
        </template>
      </el-table-column>
    </el-table>
    <printVouch ref="printVouch" name="book_all_voucher" :listQuery1="this.listQuery"></printVouch>
    <exportFile ref="exportFile" name="book_all_voucher" :listQuery1="this.listQuery"></exportFile>
  </div>
</template>

<script>

import { bookAllVoucherList } from "@/api/book.js"
import printVouch from "./printVouch.vue"
import exportFile from "./exportFile.vue"
export default {
  components: {
    printVouch,
    exportFile
  },
  data() {
    return {
      formLabelWidth: '90px',
      contentStyleObj:{}, //高度变化
      seachlist: [],
      counts : {
        count:0,
        billCount: 0
      },
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        beginTime:'',
        endTime:'',
        // subjectCode: '',
        level: 1
      },
      comName: this.$store.getters['user/comInfo'].comName,
    }
  },
  created() {
    // 获取表格高度
    this.contentStyleObj=this.$getHeight(300)
  },
  methods: {
    printing() {
      this.$refs.printVouch.init()
    },
    daochu(){
      this.$refs.exportFile.init()
    },
    getList(){
      bookAllVoucherList(this.listQuery).then(res=>{
        if(res.data.data.info) {
          this.seachlist = res.data.data.info.items
          this.counts.count = res.data.data.info.count
          this.counts.billCount = res.data.data.info.billCount
        }else {
          this.seachlist = []
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.biaotou{
  line-height: 25px;
  font-size: 14px;
  color: #333;
  width: 100%;
  padding: 5px 0 10px 0;
  span{
    display: inline-block;
    width: 33%;
  }
  span:nth-child(1){
    text-align: left;
  }
  span:nth-child(2){
    text-align: center;
  }
  span:nth-child(3){
    text-align: right;
  }
}
.top_btns {
  display: flex;
  justify-content: space-between;
  .left_box{
    float: left;
    width: 70%;
    height: 50px!important;
  }
  .right_box {
    float: right;
    width: 30%;
    text-align: right;
  }
}
.top_p{
  font-size: 15px;
  color: #333;
  // margin-bottom: 10px;
  margin-top: 2px;
}
</style>